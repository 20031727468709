// Tables
export const FIRESTORE_PROJECTS_TABLE = "projects";
export const FIRESTORE_QUOTE_REQUESTS_TABLE = "quoteRequests";
export const FIRESTORE_CONFIGURATOR_ENTRIES_TABLE = "configuratorEntries";
export const FIRESTORE_CONTACT_REQUESTS_TABLE = "contactRequests";
export const FIRESTORE_VOICE_MEETING_REQUESTS_TABLE = "voiceMeetingRequests";
export const FIRESTORE_VACANCY_APPLICATIONS_TABLE = "vacancyApplications";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_INVITED_USERS_TABLE = "invitedUsers";

// Variables
export const FIRESTORE_PRODUCT_VARIATIONS_PRICE = "price";
export const FIRESTORE_DEFAULT_ORDERS_MAX_DAYS = 30;
